<template src="./education.html"></template>

<script>
import Http from '@/shared/http-config'
import { mapFields } from 'vuex-map-fields';
import Multiselect from 'vue-multiselect'

export default {
  name: "Education",
  components: {Multiselect},
  data: function () {
    return {
      customer: {},
      new_picture: null,
      courses: []
    }
  },
  computed: {
    package_variations () {
      return this.$store.getters['customers/get']('package_variations');
    },
    ...mapFields('customers', {
      education: 'education',
      education_start: 'education.start',
      education_end: 'education.end',
      education_picture: 'education.picture',
      education_passed_talk: 'education.passed_talk',
      education_tests_theory: 'education.tests.theory',
      education_tests_praxis: 'education.tests.praxis',
    }),
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
  created: function(){
    let self = this;
    Http.request('GET', '/courses?customer_id=' + self.$route.params.id).then((courses) => {
      self.courses = courses.data;
    });

    Http.request('GET', '/customers/' + self.$route.params.id).then((customer) => {
      self.customer = customer.data;
      if(self.customer.salutation === null){
        self.customer.salutation = 1;
      }
      if(self.customer.branch === null){
        self.customer.branch = {};
      }
      if(self.customer.teacher === null){
        self.customer.teacher = {};
      }
      if(self.customer.payment.method === null){
        self.customer.payment.method = {};
      }
      if(self.customer.payment.dates.length > 0){
        self.payment_installments = true;
      }
      if(self.customer.birthday === '0000-00-00'){
        delete self.customer.birthday;
      }
      self.$store.commit('set', ['breadcrumbTxt', self.customer.name]);
    });
  },
  methods: {
    onFileChange(e) {
      this.new_picture = e.target.files[0];
    },
    async save () {
      let self = this;
      let isValid = await self.$refs.educationObserver.validate();
      if (!isValid) {
        self.$bvToast.toast('Bitte korrigiere die markierten Felder', {
          title: 'Pflichtfelder',
          variant: 'warning',
          autoHideDelay: 5000
        });
        return false;
      }

      if(self.education.tests.theory === '0000-00-00'){
        self.education.tests.theory = null;
      }
      if(self.education.tests.praxis === '0000-00-00'){
        self.education.tests.praxis = null;
      }

      let formData = new FormData();
      if(self.new_picture !== null) {
        formData.append('new_picture', self.new_picture);
      }
      self.education.picture_visibility = self.education.picture_visibility_model.reduce((pv, cv) => pv + cv, 0);
      formData.appendRecursive(self.education);

      Promise.all([
        Http.request('POST', '/customers/' + self.$route.params.id + '/educations/' + self.$route.params.education, formData),
        Http.request('POST', '/customers/' + (self.$route.params.id > 0 ? self.$route.params.id : ''), self.customer)
      ]).then(function (results) {
        self.education = results[0].data;
        self.$router.push('/customers/dashboard/' + self.$route.params.type + '/details/' + self.$route.params.id + '/' + self.$route.params.education + '/profile');
      });
    },
    courseLabel (course){
      let free_places = course.max_participants - course.num_participants;
      if(course.max_participants > 0){
        return this.$options.filters.formatDate(course.date, 'DD.MM.YY') + ' | 👥 ' + free_places + ' | ' + course.name;
      }
      else {
        return this.$options.filters.formatDate(course.date, 'DD.MM.YY') + ' | ' + course.name;
      }
    }
  }
}
</script>

<style scoped></style>
